<script setup lang="ts">
const { data, suspense } = useLayoutCmsData()

await suspense()

const cta = computed(() => data.value?.components?.footerCta)
</script>

<template>
  <section v-if="cta" class="bg-white pt-xl">
    <ContentGrid>
      <section class="full-width-content flex gap-2xl items-center">
        <div class="flex flex-col gap-m my-2xl">
          <div>
            <p class="text-h2 font-semibold" v-text="cta.title" />
            <p class="text-h2" v-text="cta.subtitle" />
          </div>
          <CrystallizeRichText v-for="(block, i) in cta.text" :key="i" class="text-2xl font-heading font-light" :block="block" />
        </div>
        <div class="flex-shrink-0 hidden md:block">
          <!-- TODO: Try to get automatic height instead of forced max -->
          <CrystallizeImage :image="cta.image?.[0]" img-class="max-h-120" />
        </div>
      </section>
    </ContentGrid>
  </section>
</template>
