<script setup lang="ts">
import type { HeaderProps } from '~/utils/header'
import { ContentGrid } from '#components'
import DesktopMenu from '~/components/header/DesktopMenu.vue'
import MobileMenu from '~/components/header/MobileMenu.vue'
import ModalSearchInput from '~/components/header/ModalSearchInput.vue'

const props = defineProps<HeaderProps>()

const openSearch = defineModel('openSearch', {
  default: false,
})

const openMenu = defineModel('openMenu', {
  default: false,
})

const headerRef = useTemplateRef<HTMLElement>('header')

const { translateY, hasScrolled, bodyAttrs, links } = useHeaderState(props, headerRef, openMenu)

const meta = useHeaderMeta(props)

const { theme } = useTheme()

useHead({
  bodyAttrs,
})
</script>

<template>
  <header ref="header" :class="headerVariants.base({ theme, open: openMenu, scrolled: hasScrolled, solid: meta.solid })">
    <component
      :is="meta.narrow ? ContentGrid : 'div'"
      :class="{ 'max-w-9xl mx-auto px-m lg:px-xl': !meta.narrow }"
    >
      <DesktopMenu
        v-model:open="openMenu"
        :left-nav-items="leftNavItems"
        :right-nav-items="rightNavItems"
      />
      <MobileMenu
        v-model:open="openMenu"
        :menu-options="links"
      />
    </component>
    <ModalSearchInput v-model:open="openSearch" />
  </header>
</template>

<style scoped lang="postcss">
:deep(.ks-dialog) {
  .ks-dialog-footer {
    @apply p-0;
  }
}

header {
  transform: translateY(v-bind(translateY));
}
</style>
