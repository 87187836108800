<script setup lang='ts'>
import { KsExpandItem, KsIcon } from '@aschehoug/kloss'
import Accordion from './Accordion.vue'
import Author from './Author.vue'
import NewsInput from './NewsInput.vue'

const { theme } = useTheme()
</script>

<template>
  <Accordion size="small">
    <KsExpandItem level="div">
      <template #prefix>
        <h5>Kontakt</h5>
      </template>
      <template #content>
        <a v-for="(item, key) in LAYOUT_CONTACT_URLS" :key="key" :href="item.url" class="px-s py-xs flex">
          {{ item.label }}
        </a>
        <span v-for="(item, key) in LAYOUT_VISIT" :key="key" class="px-s py-xs flex">
          {{ item }}
        </span>
      </template>
    </KsExpandItem>
    <KsExpandItem level="div">
      <template #prefix>
        <h5>Informasjon</h5>
      </template>
      <template #content>
        <NuxtLink v-for="(item, key) in LAYOUT_FOOTER_LINKS" :key="key" :to="item.url" class="flex px-s py-xs !no-underline hover:!underline">
          {{ item.label }}
        </NuxtLink>
      </template>
    </KsExpandItem>
    <KsExpandItem level="div">
      <template #prefix>
        <h5> En tjeneste fra</h5>
      </template>
      <template #content>
        <Author />
      </template>
    </KsExpandItem>
  </Accordion>
  <div class="flex flex-col gap-xl px-2xs">
    <div class="flex flex-col gap-2xs max-w-full">
      <h5 :class="footerVariants.newsletter.title({ theme })">
        Meld deg på vårt nyhetsbrev
      </h5>
      <NewsInput />
    </div>
    <div class="col-span-1 flex gap-s justify-start">
      <NuxtLink v-for="(social, key) in LAYOUT_SOCIAL_ICONS" :key="key" class="no-underline flex gap-s items-center dark:text-carbon-200" :href="social.url" target="_blank" :aria-label="social.label">
        <KsIcon :icon="social.icon" />
      </NuxtLink>
    </div>
  </div>
</template>
