<script setup lang="ts">
import type { NavDropdownProps } from '~/utils/nav'
import { KsIcon } from '@aschehoug/kloss'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

defineProps<NavDropdownProps>()

const { theme } = useTheme()

const [open, toggle] = useToggle(false)

const trigger = useTemplateRef<HTMLElement>('trigger')

const { height, width } = useElementSize(trigger, undefined, {
  box: 'border-box',
})

const triggerHeight = computed(() => `${height.value}px`)
const triggerWidth = computed(() => `${width.value}px`)
</script>

<template>
  <div :data-dd-privacy="privacy" :class="navItemVariants.dropdown.base({ theme, open })">
    <NavButton ref="trigger" :privacy="privacy" :icon="icon" :on-click="() => toggle()" :label="label" :open="open" class="relative z-10">
      <KsIcon :icon="faChevronDown" :class="navItemVariants.dropdown.chevron({ open })" :scale="0.6" />
    </NavButton>
    <div id="menu" :class="navItemVariants.dropdown.menu({ open })">
      <component :is="component" />
    </div>
  </div>
</template>

<style scoped>
#menu {
  --_padding: theme('spacing.s');

  /* All styles related to the padding is put here instead of in cva, so that everything is adjusted if we change the padding variable */
  padding: var(--_padding);
  padding-top: calc(var(--_padding) + var(--_padding) + v-bind(triggerHeight));
  top: calc(-1 * var(--_padding));
  margin-inline: calc(-1 * var(--_padding));
  min-width: calc(var(--_padding) + var(--_padding) + v-bind(triggerWidth));
}
</style>
