<script setup lang="ts">
import { faArrowRight, faXmark } from '@fortawesome/pro-light-svg-icons'
import SearchInput from '~/components/form/SearchInput.vue'

const open = defineModel('open', { default: false })

const KEY = 'q'

const { query, searchHistory, handleSearch } = useSearch(KEY)

function handleClose() {
  open.value = false
}
</script>

<template>
  <Modal :open="open" title="" class="!max-w-4xl shadow-modal" aria-modal="true" @close="handleClose">
    <template #body>
      <h4>Søk i hele Juridika</h4>
      <Button variant="tertiary" size="small" class="!bg-transparent !p-0 absolute right-10 top-8" @click="handleClose">
        <Shape variant="hexagon" class="w-9 fill-moss-200" :icon="faXmark" />
      </Button>
      <div class="flex flex-col items-stretch w-full max-w-5xl mx-auto py-l gap-xl">
        <form @submit.prevent="handleSearch">
          <SearchInput shape="rounded" size="large" :name="KEY" :model-value="query[KEY]" />
        </form>
        <div class="flex flex-col gap-m">
          <span class="text-caption text-blurple-500">Dine siste søk</span>
          <List v-if="searchHistory?.length" v-slot="{ item }" :items="searchHistory" size="small">
            <NuxtLink :href="`?${KEY}=${item}`" class="no-underline flex items-center w-full py-xs px-s justify-between text-small">
              {{ item }}
              <Shape variant="hexagon" :icon="faArrowRight" />
            </NuxtLink>
          </List>
        </div>
      </div>
    </template>
    <template #footer />
  </Modal>
</template>

<style scoped lang="postcss">
:deep(.ks-dialog) {
  > .ks-dialog-body {
    @apply gap-m;
  }

  > .ks-dialog-footer {
    @apply p-0 hidden !important;
  }
}

:deep(.list) {
  @apply flex flex-wrap justify-center;

  > li {
    @apply border-b border-b-carbon-100 hover:border-b-transparent rounded-none p-0;

    > a {
      > svg {
        @apply w-5 fill-moss-200 text-blurple-500 ease-out duration-300 transition-all opacity-0;
      }
    }

    &:hover {
      > a {
        > svg {
          @apply translate-x-3 opacity-100;
        }
      }
    }
  }
}
</style>
