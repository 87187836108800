<script setup lang="ts">
const navItems = useNettbutikkNavItems()

useHead({
  htmlAttrs: {
    class: 'bg-blurple-50',
  },
})
</script>

<template>
  <LayoutContainer>
    <Header v-bind="navItems" narrow solid />
    <NettbutikkHeaderBreadcrumbsAndLogout />
    <main class="flex-1">
      <slot />
    </main>
    <Footer />
  </LayoutContainer>
</template>
