<script setup lang="ts">
const openSearch = ref(false)

const navItems = useNavItems(openSearch)
</script>

<template>
  <LayoutContainer>
    <Header v-model:open-search="openSearch" v-bind="navItems" />
    <main class="flex-1">
      <slot />
    </main>
    <Footer />
  </LayoutContainer>
</template>
