/**
 * Composable for sharing header height between components. Useful for when the header is sticky, and you have other sticky content on the page that you want to offset.
 */
export function useHeaderHeight(ref?: Ref<HTMLElement | null>) {
  const value = useState('header-height', () => 0)

  if (ref) {
    const bounding = useElementBounding(ref)

    watch([bounding.height, bounding.top], ([height, top]) => {
      value.value = height + top
    })
  }

  return computed(() => `${value.value}px`)
}
