<script setup lang="ts">
import { faArrowRightFromBracket } from '@fortawesome/pro-duotone-svg-icons'

// FIXME: Currently missing a polar button color variant
const { theme } = useTheme()
</script>

<template>
  <Button
    class="w-full"
    variant="primary"
    :color="theme as ButtonColor"
    :icon="faArrowRightFromBracket"
    icon-position="left"
    external
    href="/logout"
  >
    Logg&nbsp;ut
  </Button>
</template>
