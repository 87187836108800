<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { KsIcon } from '@aschehoug/kloss'

const props = withDefaults(
  defineProps<{
    variant: ShapeVariant
    iconSize?: number
    icon?: IconDefinition
    path?: string
    split?: 'vertical' | 'horizontal'
  }>(),
  {
    iconSize: 40, // Percentage of parent
    icon: undefined,
    path: undefined,
    split: undefined,
  },
)

const VARIANTS = {
  circle: {
    path: 'M90.0402 180.471C139.705 180.471 179.967 140.209 179.967 90.5439C179.967 40.8787 139.705 0.617188 90.0402 0.617188C40.3751 0.617188 0.113525 40.8787 0.113525 90.5439C0.113525 140.209 40.3751 180.471 90.0402 180.471Z',
    size: [180, 181],
  },
  hexagon: {
    path: 'M159.344 10.7363C155.797 4.59293 149.241 0.808594 142.147 0.808594H61.2476C54.1535 0.808594 47.5981 4.59293 44.0504 10.7363L3.58684 80.8062C0.0387597 86.9503 0.0381577 94.5206 3.58526 100.665L44.0509 170.765C47.5982 176.91 54.1544 180.695 61.2497 180.695H142.145C149.24 180.695 155.796 176.91 159.344 170.765L199.809 100.665C203.356 94.5206 203.356 86.9503 199.808 80.8062L159.344 10.7363Z',
    size: [203, 181],
  },
  hexagonTilted: {
    path: 'M91.8683 2.68722L112.128 16.5781C120.066 22.021 128.4 26.8214 137.075 30.9792L159.225 41.5816C163.515 43.6227 166.085 48.1018 165.726 52.8266L163.836 77.3199C163.099 86.9017 163.099 96.5403 163.836 106.122L165.726 130.615C166.085 135.34 163.515 139.819 159.225 141.86L137.075 152.463C128.4 156.621 120.066 161.421 112.128 166.864L91.8683 180.755C87.9562 183.439 82.7967 183.439 78.8657 180.755L58.6058 166.864C50.6682 161.421 42.3337 156.621 33.659 152.463L11.5092 141.86C7.2191 139.819 4.64882 135.34 5.00791 130.615L6.89782 106.122C7.63489 96.5403 7.63489 86.9017 6.89782 77.3199L5.00791 52.8455C4.64882 48.1207 7.2191 43.6416 11.5092 41.6005L33.659 30.9981C42.3337 26.8403 50.6682 22.0399 58.6058 16.597L78.8657 2.70612C82.7778 0.0224404 87.9373 0.0224404 91.8683 2.70612V2.68722Z',
    size: [172, 183],
  },
  octagon: {
    path: 'M133.125 6.16363C129.401 2.43941 124.349 0.347168 119.083 0.347168H61.031C55.7641 0.347168 50.713 2.43941 46.9888 6.16363L5.93 47.2224C2.20577 50.9466 0.113525 55.9977 0.113525 61.2646V119.316C0.113525 124.583 2.20578 129.634 5.93 133.358L46.9888 174.417C50.713 178.141 55.7641 180.234 61.031 180.234H119.083C124.349 180.234 129.401 178.141 133.125 174.417L174.184 133.358C177.908 129.634 180 124.583 180 119.316V61.2646C180 55.9977 177.908 50.9466 174.184 47.2224L133.125 6.16363Z',
    size: [180, 181],
  },
  decagon: {
    path: 'M128.478 4.33003C125.085 1.86578 121 0.538574 116.807 0.538574H71.2588C67.0658 0.538574 62.9803 1.86577 59.5878 4.33001L22.7348 31.0994C19.3395 33.5656 16.8128 37.044 15.5172 41.0355L1.46198 84.3359C0.168791 88.3199 0.168419 92.6108 1.46091 96.595L15.5177 139.926C16.813 143.919 19.3401 147.398 22.7363 149.865L59.5878 176.634C62.9803 179.098 67.0658 180.425 71.2588 180.425H116.807C121 180.425 125.085 179.098 128.478 176.634L165.329 149.865C168.725 147.398 171.253 143.919 172.548 139.926L186.605 96.595C187.897 92.6108 187.897 88.3199 186.604 84.3359L172.548 41.0354C171.253 37.044 168.726 33.5656 165.331 31.0994L128.478 4.33003Z',
    size: [188, 181],
  },
  star: {
    path: 'M89.8467 1.56801L105.122 14.3144C107.55 16.3376 110.686 17.383 113.856 17.1469L133.717 15.8318C136.988 15.6295 139.922 17.7539 140.731 20.9236L145.621 40.2118C146.396 43.2804 148.352 45.9443 151.016 47.6641L167.843 58.2524C170.608 60.0058 171.754 63.4791 170.54 66.5139L163.155 84.9928C161.975 87.9602 161.975 91.2312 163.155 94.1986L170.54 112.677C171.754 115.712 170.641 119.186 167.843 120.939L151.016 131.527C148.318 133.213 146.396 135.877 145.621 138.98L140.731 158.268C139.922 161.437 136.988 163.596 133.717 163.36L113.856 162.044C110.686 161.842 107.55 162.854 105.122 164.877L89.8467 177.623C87.3177 179.714 83.6758 179.714 81.1805 177.623L65.9051 164.877C63.4772 162.854 60.3412 161.808 57.1714 162.044L37.31 163.36C34.0391 163.562 31.1054 161.437 30.2961 158.268L25.4066 138.98C24.631 135.911 22.6752 133.247 20.0113 131.527L3.18471 120.939C0.419623 119.186 -0.726888 115.712 0.487054 112.677L7.87188 94.1986C9.0521 91.2312 9.0521 87.9602 7.87188 84.9928L0.487054 66.5139C-0.726888 63.4791 0.385903 60.0058 3.18471 58.2524L20.0113 47.6641C22.709 45.9781 24.631 43.3141 25.4066 40.2118L30.2961 20.9236C31.1054 17.7539 34.0391 15.5958 37.31 15.8318L57.1714 17.1469C60.3412 17.3492 63.4772 16.3376 65.9051 14.3144L81.1805 1.56801C83.7096 -0.52267 87.3514 -0.52267 89.8467 1.56801Z',
    size: [172, 180],
  },
} as const satisfies Record<ShapeVariant, { path: string, size: [number, number] }>

const v = computed(() => {
  const raw = VARIANTS[props.variant]
  if (props.split === 'vertical') {
    raw.size[0] /= 2
  }
  else if (props.split === 'horizontal') {
    raw.size[1] /= 2
  }

  return raw
})

const iconOffset = computed(() => (100 - props.iconSize) / 2)
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="`0 0 ${v.size[0]} ${v.size[1]}`">
    <path :d="v.path" />
    <KsIcon v-if="icon" :icon="icon" :width="`${iconSize}%`" :height="`${iconSize}%`" :x="`${iconOffset}%`" :y="`${iconOffset}%`" />
    <slot />
  </svg>
</template>
