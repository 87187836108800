import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { cva } from 'class-variance-authority'

export type Privacy = 'mask' | 'mask-user-input' | 'allow' | 'hidden'

export type BadgeValue = number | 'alert' | 'notification'

export interface BaseNavItemProps {
  label: string
  privacy?: Privacy
  icon?: IconDefinition
  badge?: BadgeValue
}

export type NavButtonProps = BaseNavItemProps & {
  open?: boolean
  onClick?: () => void
  href?: string
} & ({
  onClick: () => void
  href?: never
} | {
  onClick?: never
  href: string
})

export type NavLinkProps = BaseNavItemProps & {
  href: string
}

export type NavDropdownProps = BaseNavItemProps & {
  component: Component
}

export type NavComponentProps = BaseNavItemProps & {
  component: Component<BaseNavItemProps>
}

export type NavItemProps =
  | (NavComponentProps & { type: 'component' })
  | (NavButtonProps & { type: 'button' })
  | (NavLinkProps & { type: 'link' })
  | (NavDropdownProps & {
    type: 'dropdown'
  })

export const navItemVariants = {
  button: {
    base: cva([
      'cursor-pointer',
      'no-underline',
      'text-small',
      'py-2xs',
      'px-xs',
      'flex',
      'items-center',
      'rounded-lg',
      'outline',
      'outline-2',
      'outline-transparent',
      'gap-2xs',
      'ease-in-out',
      'duration-300',
      'transition-all',
      'bg-transparent',
    ], {
      variants: {
        theme: {
          blurple: [
            'text-blurple-500',
            'fill-blurple-500',
            'dark:text-blurple-200',
            'hover:bg-blurple-50',
            'dark:hover:bg-blurple-400',
            'focus:bg-blurple-50',
            'dark:focus:bg-blurple-400',
            'focus:outline-blurple-300',
            'dark:focus:outline-blurple-300',
          ],
          moss: [
            'text-moss-500',
            'dark:text-moss-200',
            'hover:bg-moss-200',
            'dark:hover:bg-moss-400',
            'focus:bg-moss-200',
            'dark:focus:bg-moss-400',
          ],
          polar: [
            'text-polar-500',
            'dark:text-polar-200',
            'hover:text-polar-400',
            'dark:hover:text-polar-200',
            'focus:text-polar-400',
            'dark:focus:text-polar-200',
            'hover:bg-polar-100',
            'dark:hover:bg-polar-400',
            'focus:bg-polar-100',
            'dark:focus:bg-polar-400',
            'focus:outline-polar-400',
            'dark:focus:outline-polar-200',
          ],
          carbon: [
            'text-carbon-400',
            'hover:text-primary',
            'focus:text-primary',
            'hover:bg-carbon-100',
            'dark:hover:bg-carbon-400',
            'focus:bg-carbon-100',
            'dark:focus:bg-carbon-400',
          ],
        },
        open: {
          true: [
            'bg-transparent',
            'hover:bg-transparent',
            'focus:bg-transparent',
            'dark:bg-transparent',
            'dark:hover:bg-transparent',
            'dark:focus:bg-transparent',
          ],
          false: [],
        },
        badge: {
          true: ['pl-5'],
          false: [],
        },
      },
      compoundVariants: [
        {
          open: true,
          theme: 'blurple',
          class: ['bg-blurple-50', 'dark:text-blurple-500', 'dark:outline-blurple-500'],
        },
        {
          open: true,
          theme: 'carbon',
          class: [
            'dark:text-carbon-500',
            'dark:focus:outline-carbon-400',
            'dark:hover:text-carbon-400',
            'dark:focus:text-carbon-400',
          ],
        },
        {
          open: false,
          theme: 'carbon',
          class: [
            'dark:text-white',
            'dark:hover:text-white',
            'dark:focus:text-white',
            'dark:focus:outline-carbon-200',
          ],
        },
        {
          open: true,
          theme: 'moss',
          class: [
            'dark:text-moss-500',
            'dark:focus:outline-moss-400',
            'dark:hover:text-moss-400',
            'dark:focus:text-moss-400',
          ],
        },
        {
          open: true,
          theme: 'polar',
          class: [
            'dark:text-polar-500',
            'dark:focus:outline-polar-400',
            'dark:hover:text-polar-400',
            'dark:focus:text-polar-400',
          ],
        },
      ],
    }),
  },

  dropdown: {
    base: cva(['relative', 'transition-colors', 'duration-500', 'ease-in-out'], {
      variants: {
        theme: {
          blurple: [],
          moss: [],
          polar: [],
          carbon: [],
        },
        open: {
          true: [],
          false: [],
        },
      },
    }),

    menu: cva(['bg-white', 'absolute', 'transition-all', 'duration-300', 'ease-in-out', 'rounded-xl', 'shadow-menu'], {
      variants: {
        open: {
          true: ['opacity-100', 'visible'],
          false: ['opacity-0', 'invisible'],
        },
      },
    }),

    chevron: cva(['transition-transform', 'duration-300'], {
      variants: {
        open: {
          true: ['rotate-180'],
          false: [],
        },
      },
    }),
  },

  link: cva(['md:px-s', 'no-underline', 'flex', 'gap-2xs', 'items-center', 'relative'], {
    variants: {
      theme: {
        blurple: [
          'text-blurple-500',
          'dark:text-blurple-300',
          'hover:text-blurple-500',
          'dark:hover:text-blurple-200',
          'focus:text-blurple-500',
          'dark:focus:text-blurple-200',
          '[&>span]:before:bg-blurple-200',
        ],
        moss: [
          'text-moss-500',
          'dark:text-moss-200',
          'hover:text-moss-500',
          'dark:hover:text-moss-200',
          'focus:text-moss-500',
          'dark:focus:text-moss-200',
          '[&>span]:before:bg-moss-200',
        ],
        polar: [
          'text-polar-500',
          'dark:text-polar-200',
          'hover:text-polar-500',
          'dark:hover:text-polar-200',
          'focus:text-polar-500',
          'dark:focus:text-polar-200',
          '[&>span]:before:bg-polar-200',
        ],
        carbon: [
          'text-carbon-400',
          'dark:text-white',
          'hover:text-blurple-500',
          'dark:hover:text-white',
          'focus:text-blurple-500',
          'dark:focus:text-white',
          '[&>span]:before:bg-carbon-200',
        ],
      },
    },
  }),
}
