export const SolrParamsKey = {
  ContentType: 'contentType[]',
  FullText: 'fullText',
  LawId: 'lawId',
  PathLocation: 'pathLocation',
  CommentByLawId: 'commentByLawId',
  SearchCommentAndLaw: 'searchCommentAndLaw',
} as const

export interface SolrParams {
  [SolrParamsKey.ContentType]?: string[] // fetches by nodeType, f.ex. ['law', 'chapter']
  [SolrParamsKey.FullText]?: string // full text search
  [SolrParamsKey.LawId]?: string // fetches all documents related to a law
  [SolrParamsKey.PathLocation]?: number // fetches all documents related to a path location, applies to Ibexa documents
  [SolrParamsKey.CommentByLawId]?: string // fetches legal commentary main document related to a law ID
  [SolrParamsKey.SearchCommentAndLaw]?: CommentAndLaw // full text search for a comment and law defined by lawId and commentId
}

export interface CommentAndLaw {
  lawId?: string
  commentId?: number
}
