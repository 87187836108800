<script setup lang="ts">
import { KsDrawer, KsIcon, KsInput } from '@aschehoug/kloss'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

defineProps<{
  menuOptions: NavLinkProps[]
}>()

const open = defineModel('open', {
  default: false,
})

const { theme } = useTheme()
</script>

<template>
  <KsDrawer title=" " :open="open" size="100%" position="top" :class="headerVariants.menu.base({ theme })" :dismissable="false">
    <template #body>
      <div class="flex-1 flex flex-col gap-xl">
        <div class="flex items-center gap-x-xl full-width">
          <NavLogo :open="open" />
          <nav v-if="menuOptions.length > 0" class="flex-1 items-center flex justify-end">
            <NavMenuToggle
              v-model:open="open"
              class="!bg-transparent focus:!bg-transparent !outline-transparent"
            />
          </nav>
        </div>
        <div class="flex flex-col gap-xl px-m sm:px-l">
          <div>
            <div :class="headerVariants.menu.inputWrapper({ theme })">
              <KsInput
                type="text"
                placeholder="Søk i hele Juridika"
                :class="headerVariants.menu.input({ theme })"
              />
              <Button aria-label="Søk" variant="tertiary" size="medium" class="!p-0 absolute right-6 top-5 !bg-transparent" type="submit">
                <KsIcon :icon="faSearch" />
              </Button>
            </div>
          </div>
          <ul v-if="menuOptions.length > 0" class="flex flex-col items-start grow gap-l rounded-b-4xl">
            <li v-for="item in menuOptions" :key="item.label">
              <NuxtLink :to="item.href" class="text-h4 no-underline">
                {{ item.label }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
      <HeaderLogoutButton :open="open" />
    </template>
  </KsDrawer>
</template>

<style scoped lang='postcss'>
:deep(.ks-dialog) {
  > .ks-dialog-header {
    @apply hidden;
  }

  > .ks-dialog-body {
    @apply flex flex-col gap-m min-h-screen px-xs sm:px-m;
  }
}
</style>
