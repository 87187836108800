<script setup lang="ts">
import type { CrystallizeImage } from '~/utils/crystallize/component-schemas'

const props = defineProps<{ image?: CrystallizeImage, sizes?: string, imgClass?: string }>()

const sources = computed(() => {
  const srcsetGrouping = props.image?.variants?.reduce<Record<string, { priority: number, srcset: string[] }>>((acc, variant) => {
    const extAndPriority = getExtAndPriority(variant)
    if (extAndPriority) {
      const { ext, priority } = extAndPriority
      const type = `image/${ext === 'jpg' ? 'jpeg' : ext}`
      acc[type] ||= { priority, srcset: [] }
      acc[type].srcset.push(`${variant.url} ${variant.width}w`)
    }
    return acc
  }, {})

  if (props.image?.url?.endsWith('.svg') && srcsetGrouping) {
    // If the image is an SVG, we add the raw url to the srcset with max priority, so that, if the browser supports it, we render the raw SVG.
    // If the browser does not support SVGs in img tags, it will fall through to the next source.
    srcsetGrouping['image/svg+xml'] = { priority: -1, srcset: [props.image.url] }
  }

  return Object.entries(srcsetGrouping ?? {}).sort(([, a], [, b]) => a.priority - b.priority)
})
</script>

<template>
  <picture v-if="image">
    <source v-for="([type, { srcset }]) in sources" :key="type" :type="type" :srcset="srcset.join(', ')" :sizes="sizes">
    <img :src="image.url" :alt="image.altText ?? ''" :sizes="sizes" :class="imgClass">
  </picture>
</template>
