import { array, type InferOutput, nullish, number, object, picklist, string } from 'valibot'

export const nodeTypeSchema = picklist([
  'law',
  'chapter',
  'section',
  'subsection',
  'del',
  'paragraph',
  'note',
])

export type NodeType = InferOutput<typeof nodeTypeSchema>

export const docSchema = object({
  id: string(),
  nodeType: nullish(nodeTypeSchema),
  timestamp: string(),
  title: nullish(string()),
  shortTitle: nullish(string()),
  source: nullish(string()),
  bodytext: nullish(string()),
  _text_ngram_: nullish(array(string())),
  _version_: nullish(number()),
  _nest_parent_: nullish(array(string())),
  lawFragmentId: nullish(string()),
  path_location: nullish(array(string())),
  ezMainNodeId: nullish(number()),
})

export type SolrDoc = InferOutput<typeof docSchema>
