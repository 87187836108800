<script setup lang="ts">
const { theme } = useTheme()
</script>

<template>
  <div :class="footerVariants.author({ theme })">
    <IconsLogoAschehoug src="../public/images/aschehoug.png" alt="" aria-hidden="true" />
    <IconsLogoUniversitetsforlaget src="../public/images/universitetsforlaget.png" alt="" aria-hidden="true" />
    <span>©2024 Juridika</span>
  </div>
</template>
