import { array, intersect, literal, nullish, object, pipe, transform } from 'valibot'
import { booleanData, richTextData, singleLineData } from '~/utils/crystallize/component-schemas'
import { contentBlockPiece } from '~/utils/crystallize/pieces'

export const layoutSchema = object({
  shape: literal('layout'),
  components: object({
    footerCta: nullish(
      intersect([
        contentBlockPiece,
        object({
          subtitle: nullish(singleLineData),
        }),
      ]),
    ),

    headerCta: nullish(
      pipe(
        array(object({ show: nullish(booleanData), title: nullish(singleLineData), text: nullish(richTextData) })),
        // Non-repeatable chunk, so just get the first one
        transform(it => it[0]),
      ),
    ),
  }),
})
