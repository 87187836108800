<script setup lang="ts">
import { KsIcon } from '@aschehoug/kloss'
import { faBars, faXmark } from '@fortawesome/pro-regular-svg-icons'

const open = defineModel('open', {
  default: false,
})
</script>

<template>
  <NavButton
    :aria-label="open ? 'Lukk meny' : 'Åpne meny'"
    :label="open ? 'Lukk' : 'Meny'"
    :open="open"
    class="text-small justify-center gap-2xs flex"
    :aria-expanded="open"
    aria-haspopup="true"
    :on-click="() => open = !open"
  >
    <KsIcon :icon="open ? faXmark : faBars" :scale="0.6" />
  </NavButton>
</template>
