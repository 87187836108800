<script setup lang='ts'>
import { KsInput } from '@aschehoug/kloss'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const email = defineModel({ default: '' })

const { theme } = useTheme()
</script>

<template>
  <div :class="footerVariants.newsletter.inputWrapper({ theme })">
    <KsInput
      v-model="email"
      type="text"
      placeholder="Din e-postadresse"
      :class="footerVariants.newsletter.input({ theme })"
    />
    <Button variant="tertiary" size="medium" class="!p-0 absolute right-4 top-3 hover:!bg-transparent" type="submit" aria-label="Send inn">
      <Shape
        variant="hexagon"
        :icon="faArrowRight"
        :class="footerVariants.newsletter.inputIcon({ theme })"
      />
    </Button>
  </div>
</template>
