<script setup lang="ts">
import { KsIcon } from '@aschehoug/kloss'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const { loggedIn } = useUserSession()

const crumbs = useBreadcrumbItems()
</script>

<template>
  <div class="w-full">
    <ContentGrid>
      <div class="full-width flex justify-between items-center py-s">
        <ol class="flex py-3xs" aria-label="Brødsmulesti">
          <li
            v-for="crumb in crumbs" :key="crumb.id"
            class="text-carbon-400 dark:text-carbon-200 [&:not(:first-child)]:before:content-['/'] [&:not(:first-child)]:before:px-xs"
          >
            <NuxtLink :to="crumb.to" class="no-underline hover:text-black dark:hover:text-white text-sm" exact-active-class="text-black dark:text-white">
              {{ crumb.label }}
            </NuxtLink>
          </li>
        </ol>
        <NuxtLink v-if="loggedIn" href="/logout" external class="no-underline flex items-center gap-2xs group dark:text-white">
          Logg&nbsp;ut
          <KsIcon :icon="faArrowRight" class="group-hover:translate-x-1 transition-transform" />
        </NuxtLink>
      </div>
    </ContentGrid>
  </div>
</template>
