<script setup lang="ts">
const { data, suspense } = useLayoutCmsData()

await suspense()

const cta = computed(() => data.value?.components?.headerCta)
</script>

<template>
  <div v-if="cta?.show" class="w-full bg-[#FFEEE5] text-center py-s">
    <CrystallizeRichText
      v-for="(block, i) in cta.text"
      :key="i"
      :block="block"
      class="text-base [&_a]:font-semibold [&_a]:text-base"
    />
  </div>
</template>
