<script setup lang="ts">
import type { RichTextData } from '~/utils/crystallize/component-schemas'
import { NuxtLink } from '#components'

const props = defineProps<{
  block: RichTextData[number]
  variables?: Record<string, string | number>
}>()

const { siteUrl } = useRuntimeConfig().public

// Manipulate metadata. Currently only used to convert a tags to NuxtLink if the href is internal
const metadata = computed(() => {
  if (props.block.type === 'link') {
    const { href } = props.block.metadata ?? {}
    if (href?.startsWith(siteUrl) || href?.startsWith('/')) {
      return {
        to: href.replace(siteUrl, ''),
      }
    }
  }

  return props.block.metadata
})

// Based on https://github.com/CrystallizeAPI/reactjs-components/blob/main/src/content-transformer/component.tsx
const component = computed(() => {
  switch (props.block.type) {
    // The type is named the same as the tag
    case 'table':
    case 'span':
    case 'strong':
    case 'div':
    case 'code':
      return props.block.type

    // The type is named _almost_ the same as the tag
    case 'heading1':
    case 'heading2':
    case 'heading3':
    case 'heading4':
    case 'heading5':
    case 'heading6':
      return props.block.type.replace('heading', 'h')

    // Differs with kind
    case 'quote':
      return props.block.kind === 'block' ? 'blockquote' : 'q'

    // Everything else
    case 'link': {
      const m = metadata.value
      if (m && typeof m === 'object' && 'to' in m) {
        return NuxtLink
      }

      return 'a'
    }
    case 'unordered-list':
    case 'list':
      return 'ul'
    case 'ordered-list':
      return 'ol'
    case 'list-item':
      return 'li'
    case 'preformatted':
      return 'pre'
    case 'underlined':
      return 'u'
    case 'emphasized':
      return 'em'
    case 'line-break':
      return 'br'
    case 'deleted':
      return 'del'
    case 'inserted':
      return 'ins'
    case 'subscripted':
      return 'sub'
    case 'superscripted':
      return 'sup'
    case 'horizontal-line':
      return 'hr'
    case 'table-row':
      return 'tr'
    case 'table-cell':
      return 'td'
    case 'table-head-cell':
      return 'th'
    case 'paragraph':
      return 'p'
    default: {
      const { type, kind } = props.block
      if (type) {
        console.warn(`Unknown block type: ${type}`, props.block)
      }
      return kind === 'inline' ? null : 'div'
    }
  }
})

const allowEmpty = computed(() => typeof component.value === 'string' && ['hr', 'br'].includes(component.value))

const isEmpty = computed(() => !props.block.textContent && !props.block.children?.length)

const textContent = computed(() => interpolate(props.block.textContent, props.variables))
</script>

<template>
  <template v-if="!component && textContent">
    {{ textContent }}
  </template>
  <component :is="component" v-else-if="isEmpty && allowEmpty" v-bind="metadata ?? {}" />
  <component :is="component" v-else-if="!isEmpty" v-bind="metadata ?? {}">
    <template v-if="textContent">
      {{ textContent }}
    </template>
    <CrystallizeRichText v-for="(child, key) in block.children" v-else :key="key" :block="child" :variables="variables" />
  </component>
</template>
