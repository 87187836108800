import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faDesktop, faMoon, faSun } from '@fortawesome/pro-duotone-svg-icons'
import { type InferOutput, picklist } from 'valibot'

export const colorModeSchema = picklist([
  'system',
  'light',
  'dark',
])

export type ColorMode = InferOutput<typeof colorModeSchema>

export const COLOR_MODE_OPTIONS = [
  {
    value: 'system',
    label: 'Automatisk',
    icon: faDesktop,
  },
  {
    value: 'light',
    label: 'Lys',
    icon: faSun,
  },
  {
    value: 'dark',
    label: 'Mørk',
    icon: faMoon,
  },
] as const satisfies Options<string, { icon: IconDefinition }>

export const themeSchema = picklist([
  'blurple',
  'moss',
  'carbon',
  'polar',
])

export type Theme = InferOutput<typeof themeSchema>

export const THEME_OPTIONS = [
  {
    value: 'blurple',
    label: 'Lilla',
  },
  {
    value: 'moss',
    label: 'Grønn',
  },
  {
    value: 'carbon',
    label: 'Sort',
  },
  {
    value: 'polar',
    label: 'Blå',
  },
] as const satisfies Options<string>
