import { cva } from 'class-variance-authority'

export const footerVariants = {
  base: cva([
    'pt-2xl',
    'pb-4xl',
    'gap-19.5',
    '[&_a]:underline',
    'dark:text-carbon-200',
    'ease-in-out',
    'transition-colors',
    'duration-500',
  ], {
    variants: {
      theme: {
        blurple: ['dark:bg-blurple-500', 'text-blurple-500'],
        carbon: ['dark:bg-carbon-500', 'text-carbon-500'],
        polar: ['dark:bg-polar-500', 'text-polar-500'],
        moss: ['dark:bg-moss-500', 'text-moss-500'],
      },
    },
  }),

  author: cva(['flex', 'flex-col', 'gap-5', 'px-s', 'py-s', 'md:p-0', 'dark:text-carbon-200', '[&>svg]:dark:fill-carbon-200'], {
    variants: {
      theme: {
        blurple: ['text-blurple-500', '[&>svg]:fill-blurple-500'],
        carbon: ['text-carbon-500', '[&>svg]:fill-carbon-500'],
        polar: ['text-polar-500', '[&>svg]:fill-polar-500'],
        moss: ['text-moss-500', '[&>svg]:fill-moss-500'],
      },
    },
  }),

  newsletter: {
    title: cva([
      'text-caption',
      'font-normal',
      'dark:text-carbon-200',
    ], {
      variants: {
        theme: {
          blurple: ['text-blurple-500'],
          carbon: ['text-carbon-500'],
          polar: ['text-polar-500'],
          moss: ['text-moss-500'],
        },
      },
    }),

    inputWrapper: cva([
      'border',
      'rounded-lg',
      'relative',
      'flex',
      'items-center',
      'w-full',
      'dark:border-carbon-200',
    ], {
      variants: {
        theme: {
          blurple: ['border-blurple-500'],
          carbon: ['border-carbon-500'],
          polar: ['border-polar-500'],
          moss: ['border-moss-500'],
        },
      },
    }),

    input: cva([
      '!bg-transparent',
      '!h-14',
      '!px-6',
      'dark:text-carbon-200',
      'dark:placeholder:text-carbon-200',
    ], {
      variants: {
        theme: {
          blurple: ['text-blurple-500', 'placeholder:text-blurple-500'],
          carbon: ['dark:text-carbon-200', 'placeholder:text-blurple-500'],
          polar: ['text-polar-500', 'placeholder:text-polar-500'],
          moss: ['text-moss-500'],
        },
      },
    }),

    inputIcon: cva('w-9', {
      variants: {
        theme: {
          blurple: ['fill-blurple-400', 'dark:fill-blurple-300', 'text-white', 'dark:text-blurple-500'],
          carbon: ['fill-blurple-500', 'dark:fill-carbon-200', 'text-carbon-200', 'dark:text-carbon-500'],
          polar: ['dark:fill-polar-200', 'text-polar-200', 'dark:text-polar-500'],
          moss: ['fill-moss-300', 'text-moss-200', 'dark:fill-moss-200', 'dark:text-moss-500'],
        },
      },
    }),
  },
}
