import { faArrowRightToBracket, faSearch, faUser } from '@fortawesome/pro-duotone-svg-icons'
import UserDropdown from '~/components/nav/UserDropdown.vue'

const leftNavItems: NavItemProps[] = [
  {
    label: 'Lover og lovkommmentarer',
    href: '/lover-og-lovkommentarer',
    type: 'button',
  },
  {
    label: 'Rettsområder',
    href: '/rettsomrader',
    type: 'button',
  },
  {
    label: 'Forfattere',
    href: '/forfattere',
    type: 'button',
  },
]

export default function useNavItems(openSearch: Ref<boolean>) {
  const { loggedIn, user } = useUserSession()

  return computed<Pick<HeaderProps, 'leftNavItems' | 'rightNavItems'>>(() => ({
    leftNavItems,
    rightNavItems: [
      {
        label: 'Søk',
        icon: faSearch,
        type: 'button',
        onClick() {
          openSearch.value = !openSearch.value
        },
      },
      loggedIn.value
        ? {
            privacy: 'mask',
            label: user?.value?.fullName ?? '',
            icon: faUser,
            type: 'dropdown',
            component: UserDropdown,
          }
        : {
            label: 'Logg inn',
            icon: faArrowRightToBracket,
            type: 'button',
            href: '/logg-inn',
          },
    ],
  }))
}
