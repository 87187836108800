import { fallback, is, parse } from 'valibot'

export function useTheme() {
  const colorMode = useColorMode()
  const appConfig = useAppConfig()
  const route = useRoute()

  const resolvedTheme = computed(() => parse(
    fallback(themeSchema, appConfig.theme),
    route.meta?.theme,
  ))

  function handleColorModeChange(newColorMode: ColorMode) {
    colorMode.preference = newColorMode
  }

  function handleThemeChange(newTheme: Theme) {
    updateAppConfig({ theme: newTheme })
  }

  return {
    theme: resolvedTheme,
    handleThemeChange,
    themeChangeDisabled: computed(() => is(themeSchema, route.meta?.theme)),
    colorMode: computed(() => colorMode.preference),
    handleColorModeChange,
    colorModeChangeDisabled: computed(() => colorMode.forced),
  }
}
