import type { SolrParams } from '~/types/solr/SolrParams'
import { parse } from 'valibot'
import { solrApiResponseSchema } from '~/utils/solr/response'

function fetchFromSolr(params: SolrParams) {
  return $fetch('/api/solr/search', {
    params,
  })
}

export function useSolrContent(params: MaybeRef<SolrParams>) {
  return useQuery({
    queryKey: ['solrContent', params],
    queryFn: async () => {
      const res = await fetchFromSolr(unref(params))
      return parse(solrApiResponseSchema, res)
    },
    enabled: computed(() => Object.keys(unref(params)).length > 0),
  })
}

export async function useSolrContentAsync(params: MaybeRef<SolrParams>) {
  // If no params are passed, return null.
  // Otherwise suspense hangs indefinitely.
  if (Object.keys(unref(params)).length === 0) {
    return {
      data: ref(null),
      isLoading: ref(false),
      isError: ref(false),
    }
  }

  const q = useQuery({
    queryKey: ['solrContent', params],
    queryFn: async () => {
      const res = await fetchFromSolr(unref(params))
      return parse(solrApiResponseSchema, res)
    },
  })
  await q.suspense()
  return q
}
