<script setup lang="ts">
defineProps<{
  leftNavItems?: NavItemProps[]
  rightNavItems?: NavItemProps[]
}>()

const open = defineModel('open', {
  default: false,
})
</script>

<template>
  <div class="relative flex items-center gap-x-xl full-width">
    <NavLogo :open="open" />
    <nav class="flex-1 items-center flex justify-end">
      <NavItemList v-if="leftNavItems?.length" :items="leftNavItems" class="grow" />
      <NavItemList v-if="rightNavItems?.length" :items="rightNavItems" />
      <NavMenuToggle v-model:open="open" class="lg:hidden" />
    </nav>
  </div>
</template>
