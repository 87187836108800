import type { RequireAtLeastOne } from '#shared/params'
import { array, intersect, nullish, number, object, pipe, string, transform } from 'valibot'

export const paramsSchema = object({
  q: string(),
  defType: nullish(string()),
  fl: nullish(string()),
  rows: pipe(string(), transform(it => Number.parseInt(it, 10)), number()),
})

export const requestParamsSchema = intersect([
  paramsSchema,
  object({
    fq: nullish(array(string())),
  }),
])

export const responseParamsSchema = intersect([
  paramsSchema,
  object({
    fq: nullish(string()),
  }),
])

export enum SolrParamsKey {
  ContentType = 'contentType[]',
  FullText = 'fullText',
  LawId = 'lawId',
  PathLocation = 'pathLocation',
  CommentByLawId = 'commentByLawId',
}

export type SolrParams = RequireAtLeastOne<{
  [SolrParamsKey.ContentType]: string[] // fetches by nodeType, f.ex. ['law', 'chapter']
  [SolrParamsKey.FullText]: string // full text search
  [SolrParamsKey.LawId]: string // fetches all documents related to a law
  [SolrParamsKey.PathLocation]: number | string // fetches all documents related to a path location, applies to Ibexa documents
  [SolrParamsKey.CommentByLawId]: string // fetches legal_commentary main document related to a law ID
}>
