import { cva } from 'class-variance-authority'

export interface HeaderProps {
  leftNavItems?: NavItemProps[]
  rightNavItems?: NavItemProps[]
  narrow?: boolean
  solid?: boolean
}

export const headerVariants = {
  base: cva([
    'py-6',
    'lg:py-11',
    'sticky',
    'top-0',
    'z-10',
    'ease-in-out',
    'transition-all',
    'duration-200',
  ], {
    variants: {
      theme: {
        blurple: ['bg-blurple-100', 'dark:bg-blurple-300'],
        moss: ['dark:bg-moss-200'],
        carbon: ['bg-carbon-50', 'dark:bg-carbon-200'],
        polar: ['dark:bg-polar-200'],
      },
      open: {
        true: [],
        false: [],
      },
      solid: {
        true: [],
        false: [],
      },
      scrolled: {
        true: ['shadow-sm'],
        false: ['shadow-none'],
      },
    },
    compoundVariants: [
      {
        open: false,
        solid: true,
        class: ['bg-inherit'],
      },
      {
        open: false,
        theme: 'blurple',
        class: ['bg-blurple-100', 'dark:bg-blurple-500'],
      },
      {
        open: false,
        theme: 'moss',
        class: ['dark:bg-moss-500'],
      },
      {
        open: false,
        theme: 'carbon',
        class: ['bg-carbon-50', 'dark:bg-carbon-500'],
      },
      {
        open: false,
        theme: 'polar',
        class: ['dark:bg-polar-500'],
      },
    ],
  }),

  logo: cva([], {
    variants: {
      theme: {
        blurple: ['fill-blurple-500', 'dark:fill-blurple-200'],
        moss: ['fill-moss-500', 'dark:fill-moss-200'],
        carbon: ['fill-carbon-500', 'dark:fill-carbon-200'],
        polar: ['fill-polar-500', 'dark:fill-polar-200'],
      },
      open: {
        true: [],
        false: [],
      },
    },

    compoundVariants: [
      {
        open: true,
        theme: 'blurple',
        class: ['dark:fill-blurple-500'],
      },
      {
        open: true,
        theme: 'moss',
        class: ['dark:fill-moss-500'],
      },
      {
        open: true,
        theme: 'carbon',
        class: ['dark:fill-carbon-500'],
      },
      {
        open: true,
        theme: 'polar',
        class: ['dark:fill-polar-500'],
      },
    ],
  }),

  navLink: {
    base: cva([
      'cursor-pointer',
      'no-underline',
      'group',
      'text-small',
      'py-2xs',
      'px-xs',
      'flex',
      'items-center',
      'rounded-lg',
      'outline',
      'outline-2',
      'outline-transparent',
      'gap-2xs',
      'ease-in-out',
      'duration-300',
      'transition-all',
    ], {
      variants: {
        theme: {
          blurple: [
            'text-blurple-500',
            'dark:text-blurple-300',
            'hover:bg-blurple-50',
            'dark:hover:bg-blurple-400',
            'focus:bg-blurple-50',
            'dark:focus:bg-blurple-400',
            'focus:outline-blurple-300',
            'dark:focus:outline-blurple-300',
          ],
          moss: [
            'text-moss-500',
            'dark:text-moss-200',
            'hover:bg-moss-200',
            'dark:hover:bg-moss-400',
            'focus:bg-moss-200',
            'dark:focus:bg-moss-400',
            'focus:outline-moss-200',
          ],
          polar: [
            'text-polar-500',
            'dark:text-polar-200',
            'hover:text-polar-400',
            'hover:bg-polar-100',
            'dark:hover:bg-polar-400',
            'dark:hover:text-polar-100',
            'focus:bg-polar-100',
            'dark:focus:bg-polar-400',
            'focus:outline-polar-400',
            'dark:focus:outline-polar-200',
          ],
          carbon: [
            'text-carbon-400',
            'dark:text-white',
            'hover:text-blurple-500',
            'dark:hover:text-white',
            'hover:bg-carbon-100',
            'dark:hover:bg-carbon-400',
            'focus:bg-carbon-100',
            'dark:focus:bg-carbon-400',
            'focus:outline-carbon-200',
            'dark:focus:outline-carbon-200',
          ],
        },
        selected: {
          true: [],
          false: [],
        },
        mobile: {
          true: [],
          false: [],
        },
      },
      compoundVariants: [
        {
          selected: true,
          theme: 'blurple',
          class: [
            'bg-blurple-50',
            'dark:bg-blurple-400',
            'focus:outline-blurple-300',
            'dark:focus:outline-blurple-200',
          ],
        },
        {
          theme: 'blurple',
          mobile: true,
          class: ['dark:text-blurple-500'],
        },
        {
          selected: true,
          theme: 'moss',
          class: [
            'bg-moss-200',
            'dark:bg-moss-400',
            'focus:outline-moss-300',
            'dark:focus:outline-moss-200',
          ],
        },
        {
          theme: 'moss',
          mobile: true,
          class: ['dark:text-moss-500'],
        },
        {
          selected: true,
          theme: 'polar',
          class: [
            'bg-polar-100',
            'dark:bg-polar-400',
            'focus:outline-polar-400',
            'dark:focus:outline-polar-200',
          ],
        },
        {
          theme: 'polar',
          mobile: true,
          class: ['dark:text-polar-500'],
        },
        {
          selected: true,
          theme: 'carbon',
          class: [
            'bg-carbon-50',
            'dark:bg-carbon-400',
            'hover:bg-carbon-100',
            'dark:hover:bg-carbon-400',
            'focus:bg-carbon-100',
            'dark:focus:bg-carbon-400',
            'focus:outline-carbon-200',
          ],
        },
        {
          theme: 'carbon',
          mobile: true,
          class: ['dark:text-carbon-500'],
        },
      ],
    }),

    icon: cva([
      'ease-out',
      'duration-300',
      'transition-all',
    ], {
      variants: {
        theme: {
          blurple: [
            'fill-blurple-500',
            'text-blurple-500',
            'dark:text-blurple-300',
            'group-hover:text-blurple-500',
            'dark:group-hover:text-blurple-200',
            'dark:group-focus:text-blurple-200',
          ],
          carbon: [
            'fill-blurple-500',
            'text-carbon-500',
            'dark:text-inverse',
            'group-hover:text-carbon-500',
            'dark:group-hover:text-white',
            'dark:group-focus:text-white',
          ],
          moss: [
            'fill-moss-500',
            'text-moss-500',
            'dark:text-moss-200',
            'group-hover:text-moss-500',
            'dark:group-hover:text-moss-200',
            'dark:group-focus:text-moss-200',
          ],
          polar: [
            'fill-polar-500',
            'dark:text-polar-200',
            'text-polar-500',
            'dark:text-tertiary-on_dark',
            'group-hover:text-polar-500',
            'dark:group-hover:text-polar-200',
            'dark:group-focus:text-polar-200',
          ],
        },
      },
    }),

    chevron: cva([
      'fill-moss-200',
      'text-blurple-500',
      'ease-out',
      'duration-300',
      'transition-all',
    ], {
      variants: {
        theme: {
          blurple: [
            'fill-blurple-500',
            'dark:text-blurple-200',
            'group-hover:text-blurple-500',
            'dark:group-hover:text-blurple-200',
            'dark:group-focus:text-blurple-200',
          ],
          carbon: [
            'fill-blurple-500',
            'dark:text-white',
            'group-hover:text-blurple-500',
            'dark:group-hover:text-white',
            'dark:group-focus:text-white',
          ],
          moss: [
            'fill-moss-500',
            'dark:text-moss-200',
            'dark:group-hover:text-moss-200',
            'dark:group-focus:text-moss-200',
          ],
          polar: [
            'fill-polar-500',
            'dark:text-polar-200',
          ],
        },
        selected: {
          true: ['rotate-180'],
          false: ['rotate-0'],
        },
      },
    }),
  },

  menu: {
    base: cva([], {
      variants: {
        theme: {
          blurple: [
            'dark:[--ks-surface:theme(colors.blurple.300)]',
            '[&_a]:dark:text-blurple-500',
          ],
          moss: [
            'dark:[--ks-surface:theme(colors.moss.200)]',
            '[&_a]:dark:text-moss-500',
          ],
          carbon: [
            'dark:[--ks-surface:theme(colors.carbon.200)]',
            '[&_a]:dark:text-carbon-500',
          ],
          polar: [
            'dark:[--ks-surface:theme(colors.polar.200)]',
            '[&_a]:dark:text-polar-500',
          ],
        },
      },
    }),

    inputWrapper: cva([
      'border',
      'rounded-full',
      'p-s',
      'h-16',
      'relative',
      'flex',
      'w-full',
      'items-center',
    ], {
      variants: {
        theme: {
          blurple: ['border-blurple-500'],
          moss: ['border-moss-500'],
          polar: ['border-polar-500'],
          carbon: ['border-carbon-500'],
        },
      },
    }),

    input: cva([
      '!bg-transparent',
      '!p-0',
      '!text-small',
    ], {
      variants: {
        theme: {
          blurple: [
            'dark:text-blurple-500',
            'placeholder:text-blurple-500',
          ],
          moss: [
            'dark:text-moss-500',
            'placeholder:text-moss-400',
          ],
          carbon: [
            'dark:text-carbon-200',
            'placeholder:text-blurple-500',
          ],
          polar: [
            'dark:text-polar-500',
            'placeholder:text-polar-500',
          ],
        },
      },
    }),
  },
}
