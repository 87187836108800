import type { InferOutput } from 'valibot'
import { array, boolean, intersect, literal, nullish, number, object, pipe, string, transform } from 'valibot'
import { baseCatalogueSchema } from '~/utils/crystallize/catalogue'
import {
  genericComponents,
  image,
  imagesData,
  numericData,
  relatedDocumentsData,
  richTextData,
  singleLineData,
} from '~/utils/crystallize/component-schemas'
import { contentBlockPiece, quotePiece, templatePiece } from '~/utils/crystallize/pieces'

/**
 * Similarly to {@link transformComponents}, we convert an array of price variants to an object of prices with camelCased keys.
 *
 * @param variants
 */
export function transformPriceVariants(variants: { identifier: string, price: number }[]) {
  return variants.reduce((acc, { identifier, price }) => ({
    ...acc,
    [toCamelCase(identifier)]: price,
  }), {})
}

const priceVariants = pipe(
  array(
    object({
      identifier: string(),
      price: number(),
    }),
  ),
  transform(transformPriceVariants),
  object({
    default: number(),
  }),
)

const productVariantComponents = object({
  title: nullish(singleLineData),
  subtitle: nullish(singleLineData),
  description: nullish(richTextData),
  serie: nullish(singleLineData),
  componenttype: nullish(singleLineData),
  effectiveVatPercent: nullish(numericData),
  thema1: nullish(singleLineData),
  thema2: nullish(singleLineData),
  thema3: nullish(singleLineData),
  thema4: nullish(singleLineData),
})

export const productVariant = object({
  name: string(),
  sku: string(),
  isDefault: boolean(),
  components: intersect([
    genericComponents,
    productVariantComponents,
  ]),
  price: number(),
  priceVariants,
  firstImage: nullish(image),
  subscriptionPlans: nullish(
    array(
      object({
        identifier: string(),
        periods: array(
          object({
            id: string(),
            initial: nullish(object({ priceVariants })),
            recurring: nullish(object({ priceVariants })),
          }),
        ),
      }),
    ),
  ),
})

export type ProductVariant = InferOutput<typeof productVariant>

const productComponents = object({
  title: nullish(singleLineData),
  // It's a requirement from Core that this has a norwegian name...
  tittel: nullish(singleLineData),
  subtitle: nullish(singleLineData),
  illustration: nullish(imagesData),
  quote: nullish(quotePiece),
  legalCommentary: nullish(contentBlockPiece),
  journals: nullish(contentBlockPiece),
  templateMetadata: nullish(contentBlockPiece),
  templates: nullish(
    array(
      object({
        template: nullish(templatePiece),
      }),
    ),
  ),
  relatedProducts: nullish(relatedDocumentsData),
  includedText: nullish(richTextData),
  includedList: nullish(
    array(
      object({
        text: nullish(singleLineData),
      }),
    ),
  ),
  literature: nullish(
    array(
      object({
        metadata: nullish(contentBlockPiece),
        items: nullish(relatedDocumentsData),
      }),
    ),
  ),
})

const baseProductSchema = object({
  type: literal('product'),
  vatType: object({
    name: string(),
    percent: number(),
  }),
  variants: array(productVariant),
  components: intersect([
    genericComponents,
    productComponents,
  ]),
})

export const productSchema = intersect([
  baseCatalogueSchema,
  baseProductSchema,
])

export type Product = InferOutput<typeof productSchema>
