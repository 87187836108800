import { type InferOutput, nullish, object } from 'valibot'
import { datetimeData, imagesData, richTextData, singleLineData } from '~/utils/crystallize/component-schemas'

/**
 * @see https://app.crystallize.com/@juridika-test/no-nb/settings/shapes/piece/content-block
 */
export const contentBlockPiece = object({
  title: nullish(singleLineData),
  text: nullish(richTextData),
  image: nullish(imagesData),
})

export type ContentBlock = InferOutput<typeof contentBlockPiece>

/**
 * @see https://app.crystallize.com/@juridika-test/no-nb/settings/shapes/piece/template
 */
export const templatePiece = object({
  title: nullish(singleLineData),
  updatedat: nullish(datetimeData),
})

/**
 * @see https://app.crystallize.com/@juridika-test/no-nb/settings/shapes/piece/person
 */
export const personPiece = object({
  name: nullish(singleLineData),
  title: nullish(singleLineData),
  image: nullish(imagesData),
})

/**
 * @see https://app.crystallize.com/@juridika-test/no-nb/settings/shapes/piece/quote
 */
export const quotePiece = object({
  text: nullish(richTextData),
  author: nullish(personPiece),
})
