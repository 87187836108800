import defu from 'defu'
import { boolean, fallback, type InferOutput, object, optional, parse } from 'valibot'

const metaSchema = object({
  // Whether the header should be narrow (suitable for e.g. nettbutikk routes)
  narrow: fallback(optional(boolean()), false),

  // Whether the header should be solid (i.e. not transparent) in light mode
  solid: fallback(optional(boolean()), false),
})

export type HeaderMeta = InferOutput<typeof metaSchema>

/**
 * Merge metadata from route meta and props, with the route-specific metadata taking precedence.
 */
export function useHeaderMeta(props: HeaderProps & HeaderMeta) {
  const route = useRoute()

  return computed(() => parse(metaSchema, defu<HeaderMeta, [HeaderMeta]>(route.meta.header ?? {}, props ?? {})))
}
