<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { KsIcon } from '@aschehoug/kloss'

const props = defineProps<{
  icon: IconDefinition
  badge?: BadgeValue
  scale?: number
}>()

const badgeRef = useTemplateRef<HTMLDivElement>('badge')

// Make the badge pop when the value changes
watch(() => props.badge, (newValue, oldValue) => {
  if (oldValue === newValue || !badgeRef.value) {
    return
  }

  badgeRef.value.classList.remove('animate-pop')
  // This looks strange, but we need to trigger a reflow for the animation to work more than once. Calling offsetWidth on an element will do that.
  void badgeRef.value.offsetWidth
  badgeRef.value.classList.add('animate-pop')
})

const { theme } = useTheme()

const translateX = computed(() => {
  const scale = props.scale || 1
  const factor = props.badge === 'alert' ? 6 : props.badge === 'notification' ? 5 : 2.5
  return props.badge ? `${-0.3 * factor * scale}rem` : '0'
})

const top = computed(() => `${-0.125 * (props.scale || 1)}rem`)
</script>

<template>
  <ClientOnly>
    <KsIcon :icon="icon" class="transition-all" :scale="scale" />
    <div
      ref="badge"
      aria-hidden="true"
      :class="iconBadgeVariants({ theme, visible: !!badge, alert: badge === 'alert', notification: badge === 'notification' })"
    >
      {{ typeof badge === 'number' ? badge : '' }}
    </div>
  </ClientOnly>
</template>

<style scoped>
svg {
  transform: translateX(v-bind(translateX));
}

div {
  transform: scale(v-bind(scale));
  top: v-bind(top);
}
</style>
