import { HeaderLoginButton } from '#components'
import { faShoppingBasket } from '@fortawesome/pro-duotone-svg-icons'

export function useNettbutikkNavItems() {
  const { loggedIn } = useUserSession()

  const products = useProductCatalogue()

  const highlightedNavItem = computed<NavItemProps>(() => {
    const p = products.data.value?.highlighted
    return p
      ? {
          type: 'link',
          label: getTitle(p) ?? 'Juridika Premium',
          href: p.path,
        }
      : {
          type: 'link',
          label: 'Juridika Premium',
          // Sensible default so that the link will work before hydration/the product fetch is complete
          href: '/produktkatalog/juridika-premium',
        }
  })

  const { size } = useCartMetadata()

  return computed<Pick<HeaderProps, 'leftNavItems' | 'rightNavItems'>>(() => ({
    leftNavItems: [
      {
        type: 'link',
        label: 'Våre abonnement',
        href: '/produktkatalog',
      },
      highlightedNavItem.value,
    ],
    rightNavItems: [
      {
        type: 'link',
        label: 'Handlekurv',
        href: '/handlekurv',
        icon: faShoppingBasket,
        badge: size.value,
      },
      {
        type: 'component',
        label: loggedIn.value ? 'Din\xA0konto' : 'Logg\xA0inn',
        component: HeaderLoginButton,
      },
    ],
  }))
}
