import type { InferOutput } from 'valibot'
import { array, nullish, object, record, string } from 'valibot'
import { bodySchema } from './body'
import { headersSchema } from './headers'

export const solrHighlightingSchema = record(
  string(),
  object({
    bodytext: nullish(array(string())),
    title: nullish(array(string())),
  }),
)

export type SolrHighlighting = InferOutput<typeof solrHighlightingSchema>

export const solrApiResponseSchema = object({
  responseHeader: headersSchema,
  response: bodySchema,
  highlighting: nullish(solrHighlightingSchema),
})
