import { parse } from 'valibot'
import { layoutSchema } from '~/utils/crystallize/layout'

export function useLayoutCmsData() {
  const options = useCatalogueQueryOptions('/layout')

  return useQuery({
    ...options,
    staleTime: 1000 * 60 * 60, // 1 hour
    async queryFn() {
      const catalogue = await options.queryFn()
      return parse(layoutSchema, catalogue)
    },
  })
}
