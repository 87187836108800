import type { InferOutput } from 'valibot'
import { array, boolean, nullish, number, object, record, string } from 'valibot'
import { docSchema } from '~/utils/solr/doc'
import { bodySchema } from './body'
import { headersSchema } from './headers'

export const responseBodySchema = object({
  numFound: number(),
  start: number(),
  numberFoundExact: nullish(boolean()),
  docs: array(docSchema),
})

export const solrHighlightingSchema = record(
  string(),
  object({
    bodytext: nullish(array(string())),
    title: nullish(array(string())),
  }),
)

export type SolrHighlighting = InferOutput<typeof solrHighlightingSchema>

export const solrApiResponseSchema = object({
  responseHeader: headersSchema,
  response: bodySchema,
  highlighting: nullish(solrHighlightingSchema),
})
