<script setup lang="ts">
import type { BaseNavItemProps } from '~/utils/nav'
import { faArrowRightToBracket } from '@fortawesome/pro-duotone-svg-icons'

defineProps<BaseNavItemProps>()

const { loggedIn } = useUserSession()
</script>

<template>
  <Button v-if="loggedIn" variant="secondary" to="/din-konto" size="small">
    {{ label }}
  </Button>
  <Button v-else variant="primary" to="/logg-inn" :icon="faArrowRightToBracket" size="small">
    {{ label }}
  </Button>
</template>
