<script setup lang='ts'>
import { faPalette } from '@fortawesome/pro-duotone-svg-icons'
import Dropdown from '../form/Dropdown.vue'

const { colorMode, handleColorModeChange, colorModeChangeDisabled, theme, handleThemeChange, themeChangeDisabled } = useTheme()

const colorModeModel = ref(COLOR_MODE_OPTIONS.find(it => it.value === colorMode.value))
const themeModel = ref(THEME_OPTIONS.find(it => it.value === theme.value))
</script>

<template>
  <div v-if="!colorModeChangeDisabled || !themeChangeDisabled" class="flex flex-col gap-m">
    <h5>Preferanser</h5>
    <label v-if="!colorModeChangeDisabled" for="colorMode" aria-label="Stil" class="flex flex-col gap-2xs">
      <span>Stil</span>
      <Dropdown
        id="colorMode"
        v-model="colorModeModel"
        :options="COLOR_MODE_OPTIONS"
        variant="outlined"
        :icon="faPalette"
        icon-position="left"
        :multiple="false"
        placeholder="Velg stil..."
        :option-icon="it => it.icon"
        :option-label="it => it.label"
        :option-id="it => it.value"
        :deselectable="() => false"
        @select="(it) => handleColorModeChange(it.value)"
      />
    </label>
    <label v-if="!themeChangeDisabled" for="theme" aria-label="Fargetema" class="flex flex-col gap-2xs">
      <span>Fargetema</span>
      <Dropdown
        id="theme"
        v-model="themeModel"
        :options="THEME_OPTIONS"
        variant="outlined"
        :icon="faPalette"
        icon-position="left"
        :multiple="false"
        placeholder="Velg fargetema..."
        :option-label="it => it.label"
        :option-id="it => it.value"
        :deselectable="() => false"
        @select="(it) => handleThemeChange(it.value)"
      />
    </label>
  </div>
</template>
