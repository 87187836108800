<script setup lang="ts">
import type { NuxtLinkProps } from '#app'

defineProps<NuxtLinkProps & {
  open?: boolean
}>()

const { theme } = useTheme()
</script>

<template>
  <NuxtLink v-bind="$props" :to="HOMEPAGE_URL" aria-label="Hjem">
    <IconsLogoJuridika aria-hidden="true" :class="headerVariants.logo({ theme, open })" />
  </NuxtLink>
</template>
