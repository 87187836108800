<script setup lang="ts">
import type { NavLinkProps } from '~/utils/nav'

defineProps<NavLinkProps>()

const { theme } = useTheme()
</script>

<template>
  <NuxtLink :to="href" :class="navItemVariants.link({ theme })" class="group" :data-dd-privacy="privacy" exact-active-class="active">
    <IconWithBadge v-if="icon" :icon="icon" :badge="badge" />
    <span
      class="label no-underline text-sm md:text-lg text-center relative before:h-3xs before:rounded-[0.25rem] before:-bottom-2xs before:absolute before:[transform:scaleX(0)] group-hover:before:[transform:scaleX(1)] group-[.active]:before:[transform:scaleX(1)]"
      v-text="label"
    />
  </NuxtLink>
</template>

<style scoped>
.label:before {
  --_width: 60%;

  width: var(--_width);
  left: calc((100% - var(--_width)) / 2);
  transition: transform 0.2s ease;
}
</style>
