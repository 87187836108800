import type { SolrParams } from '~/types/solr/SolrParams'
import { SolrParamsKey } from '~/types/solr/SolrParams'
import { useSolrContent } from './useSolr'

export function useSearch(KEY = 'q', withHistory = true) {
  const { query } = useRoute()

  const queryOptionsRef = ref<SolrParams>({})
  // FIXME: Remove this mock data
  const searchHistory = ref<Array<string>>(MOCK_SEARCH_HISTORY)

  const solrQuery = useSolrContent(queryOptionsRef)
  const searchResult = computed(() => solrQuery.data?.value ?? null)

  async function handleSearch(event: Event, queryOptions: SolrParams = {}) {
    const data = new FormData(event.target as HTMLFormElement)
    const queryValue = data.get(KEY) as string

    if (!queryValue) {
      return
    }

    // Have to run this first otherwise query in URL is not updated on first search
    await resetSearch()

    queryOptionsRef.value = {
      [SolrParamsKey.FullText]: queryValue,
      ...queryOptions,
    }

    if (withHistory) {
      addSearchToHistory(queryValue)
    }

    navigateTo({
      query: {
        [KEY]: queryValue,
      },
    })
  }

  function addSearchToHistory(searchTerm: string) {
    // TODO: API call to save search term
    if (searchTerm && !searchHistory.value.includes(searchTerm)) {
      searchHistory.value.push(searchTerm)
    }
  }

  async function resetSearch() {
    queryOptionsRef.value = {} // Clear query options
    await navigateTo({ query: { [KEY]: undefined } }) // Clear query param in the URL
  }

  return {
    searchResult,
    query,
    handleSearch,
    searchHistory,
    resetSearch,
  }
}
