<script setup lang="ts">
const openSearch = ref(false)

const navItems = useNavItems(openSearch)
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <Header v-model:open-search="openSearch" v-bind="navItems" />
    <main class="flex-1">
      <slot />
    </main>
  </div>
</template>
