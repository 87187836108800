<template>
  <component :is="$attrs.is || 'div'" class="content-grid">
    <slot />
  </component>
</template>

<style scoped lang='postcss'>
.content-grid {
  --_max-width: theme('screens.2xl');
  --_base-padding: theme('spacing.l');
  --_large-padding: theme('spacing[12.5]');
  --gutter: theme('spacing.6');

  @apply relative max-w-[--_max-width] mx-auto grid grid-cols-4 gap-x-[--gutter] px-[--_base-padding] lg:grid-cols-8 lg:px-[--_large-padding] 2xl:grid-cols-12;
}

/* The utils below lets us color the remaining space outside a content-grid with the --before-bg and --after-bg CSS variables */
.content-grid-pseudo {
  --_extra-horizontal-space: calc((theme('width.screen') - var(--_max-width)) / 2);
  --_2xl-width: calc(var(--_extra-horizontal-space) + var(--_large-padding));

  @apply absolute content-[''] h-full top-0 w-[--_base-padding] lg:w-[--_large-padding] 2xl:w-[--_2xl-width];
}

.content-grid:before {
  @apply content-grid-pseudo left-0 2xl:-left-[--_extra-horizontal-space] bg-[--before-bg];
}

.content-grid:after {
  @apply content-grid-pseudo right-0 2xl:-right-[--_extra-horizontal-space] bg-[--after-bg];
}
</style>
