import { cva, type VariantProps } from 'class-variance-authority'

export type BadgeVariantProps = VariantProps<typeof badgeVariants>

export type BadgeIntent = NonNullable<BadgeVariantProps['intent']>

export type BadgeSize = NonNullable<BadgeVariantProps['size']>

export const badgeVariants = cva([
  'w-fit',
  'flex',
  'justify-center',
  'items-center',
  'gap-3xs',
  'rounded-full',
], {
  variants: {
    intent: {
      info: ['bg-blue-50', 'text-blurple-500'],
      warning: ['bg-yellow-50', 'text-blurple-500'],
      success: ['bg-moss-200', 'text-moss-500'],
      danger: ['bg-red-50', 'text-blurple-500'],
    },
    size: {
      small: ['p-3xs'],
      medium: ['p-2xs', 'has-[span]:text-caption'],
      large: ['p-2xs', 'has-[span]:py-3xs', 'has-[span]:text-caption'],
    },
  },
  compoundVariants: [],
})

export const iconBadgeVariants = cva([
  'absolute',
  'transition-all',
  'rounded-full',
  'flex',
  'justify-center',
  'items-center',
  'text-caption',
  'origin-left',
], {
  variants: {
    theme: {
      blurple: [
        'bg-blurple-400',
        'dark:bg-blurple-100',
        'text-blurple-50',
        'dark:text-blurple-500',
      ],
      moss: [
        'bg-moss-400',
        'dark:bg-moss-100',
        'text-moss-50',
        'dark:text-moss-500',
      ],
      polar: [
        'bg-polar-400',
        'dark:bg-polar-100',
        'text-polar-50',
        'dark:text-polar-500',
      ],
      carbon: [
        'bg-carbon-400',
        'dark:bg-carbon-100',
        'text-carbon-50',
        'dark:text-carbon-500',
      ],
    },
    visible: {
      true: ['opacity-100'],
      false: ['opacity-0', 'size-0'],
    },
    alert: {
      true: ['!bg-[#FF7F50]'],
      false: [],
    },
    notification: {
      true: ['bg-moss-200'],
      false: [],
    },
  },
  compoundVariants: [
    {
      visible: true,
      alert: true,
      class: ['size-2'],
    },
    {
      visible: true,
      notification: true,
      class: ['size-1.5'],
    },
    {
      visible: true,
      alert: false,
      notification: false,
      class: ['size-4'],
    },
  ],
})
