import { datadogRum } from '@datadog/browser-rum'
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental'
import {
  dehydrate,
  hydrate,
  QueryCache,
  QueryClient,
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from '@tanstack/vue-query'

const FIVE_MINUTES = 1000 * 60 * 5

export default defineNuxtPlugin((nuxt) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: FIVE_MINUTES,
      },
    },
    queryCache: new QueryCache({
      onError(error, query) {
        if (import.meta.server || import.meta.dev) {
          console.error(error, query.queryKey)
        }
        else if (import.meta.client) {
          datadogRum.addError(error, {
            resource: query.queryKey,
          })
        }
      },
    }),
  })

  const options: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, options)

  if (import.meta.server) {
    nuxt.hooks.hook('app:rendered', () => {
      nuxt.payload.query = dehydrate(queryClient)
    })
  }

  if (import.meta.client) {
    broadcastQueryClient({ queryClient })
    hydrate(queryClient, nuxt.payload.query)
  }
})
