import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior(to, _from, savedPosition) {
    if (to.path.startsWith('/din-konto/')) {
      return savedPosition || {
        el: '#route-container',
      }
    }

    return { top: 0, left: 0 }
  },
}
