<script setup lang="ts">
import type { NavButtonProps } from '~/utils/nav'
import { NuxtLink } from '#components'

defineProps<NavButtonProps>()

const { theme } = useTheme()
</script>

<template>
  <component :is="href ? NuxtLink : 'button'" :class="navItemVariants.button.base({ theme, open, badge: !!badge })" :data-dd-privacy="privacy" :to="href" @click="onClick">
    <IconWithBadge v-if="icon" :icon="icon" :badge="badge" />
    {{ label }}
    <slot />
  </component>
</template>
