<script setup lang='ts'>
import { KsExpand } from '@aschehoug/kloss'

const { theme } = useTheme()
</script>

<template>
  <ClientOnly>
    <KsExpand size="small" :class="`ks-expand-${theme}`">
      <slot />
    </KsExpand>
  </ClientOnly>
</template>

<style scoped lang='postcss'>
.ks-expand {
  @apply gap-m;

  &.ks-expand-small {
    @apply text-4xl  font-light;

    :deep(.ks-expand-toggle) {
      .ks-expand-button {
        @apply px-s py-xs !important;
      }
    }
  }

  &.ks-expand-blurple {
    :deep(.ks-expand-toggle) {
      @apply hover:rounded-lg hover:bg-blurple-100 dark:hover:bg-transparent outline focus:outline-blurple-300 focus:outline focus:outline-2 focus:border-transparent !important;

      &:has(> .ks-expand-button[aria-expanded='false']) {
        @apply border-b border-blurple-500 hover:border-transparent !important;
      }

      &:has(> .ks-expand-button[aria-expanded='true']) {
        @apply border-b border-transparent !important;
      }

      .ks-expand-button {
        @apply bg-transparent text-blurple-500 dark:text-carbon-200 active:text-blurple-500 justify-start !important;

        > svg {
          @apply w-5 h-5 !important;
        }
      }

      .ks-expand-button[aria-expanded='true'] {
        @apply bg-transparent border-white rounded-none rounded-t-lg active:text-blurple-500 hover:bg-transparent justify-start !important;
      }
    }

    :deep(.ks-expand-content) {
      @apply bg-transparent border-b border-blurple-500 !important;

      > div {
        @apply p-0 dark:text-carbon-200 !important;
      }
    }
  }

  &.ks-expand-carbon {
    :deep(.ks-expand-toggle) {
      @apply hover:rounded-lg hover:bg-carbon-100 dark:hover:bg-transparent outline focus:outline-carbon-300 focus:outline focus:outline-2 focus:border-transparent !important;

      &:has(> .ks-expand-button[aria-expanded='false']) {
        @apply border-b border-carbon-500 hover:border-transparent !important;
      }

      &:has(> .ks-expand-button[aria-expanded='true']) {
        @apply border-b border-transparent !important;
      }

      .ks-expand-button {
        @apply bg-transparent text-carbon-500 dark:text-carbon-200 active:text-carbon-500 justify-start !important;

        > svg {
          @apply w-5 h-5 !important;
        }
      }

      .ks-expand-button[aria-expanded='true'] {
        @apply bg-transparent border-white rounded-none rounded-t-lg active:text-carbon-500 hover:bg-transparent justify-start !important;
      }
    }

    :deep(.ks-expand-content) {
      @apply bg-transparent border-b border-carbon-500 !important;

      > div {
        @apply p-0 dark:text-carbon-200 !important;
      }
    }
  }

  &.ks-expand-polar {
    :deep(.ks-expand-toggle) {
      @apply hover:rounded-lg hover:bg-polar-100 dark:hover:bg-transparent outline focus:outline-polar-300 focus:outline focus:outline-2 focus:border-transparent !important;

      &:has(> .ks-expand-button[aria-expanded='false']) {
        @apply border-b border-polar-500 hover:border-transparent !important;
      }

      &:has(> .ks-expand-button[aria-expanded='true']) {
        @apply border-b border-transparent !important;
      }

      .ks-expand-button {
        @apply bg-transparent text-polar-500 dark:text-carbon-200 active:text-polar-500 justify-start !important;

        > svg {
          @apply w-5 h-5 !important;
        }
      }

      .ks-expand-button[aria-expanded='true'] {
        @apply bg-transparent border-white rounded-none rounded-t-lg active:text-polar-500 hover:bg-transparent justify-start !important;
      }
    }

    :deep(.ks-expand-content) {
      @apply bg-transparent border-b border-polar-500 !important;

      > div {
        @apply p-0 dark:text-carbon-200 !important;
      }
    }
  }

  &.ks-expand-moss {
    :deep(.ks-expand-toggle) {
      @apply hover:rounded-lg hover:bg-moss-100 dark:hover:bg-transparent outline focus:outline-moss-300 focus:outline focus:outline-2 focus:border-transparent !important;

      &:has(> .ks-expand-button[aria-expanded='false']) {
        @apply border-b border-moss-500 hover:border-transparent !important;
      }

      &:has(> .ks-expand-button[aria-expanded='true']) {
        @apply border-b border-transparent !important;
      }

      .ks-expand-button {
        @apply bg-transparent text-moss-500 dark:text-carbon-200 active:text-moss-500  justify-start !important;

        > svg {
          @apply w-5 h-5 !important;
        }
      }

      .ks-expand-button[aria-expanded='true'] {
        @apply bg-transparent border-white rounded-none rounded-t-lg active:text-moss-500 justify-start !important;
      }
    }

    :deep(.ks-expand-content) {
      @apply bg-transparent border-b border-moss-500 !important;

      > div {
        @apply p-0 dark:text-carbon-200 !important;
      }
    }
  }
}
</style>
