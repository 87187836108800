<template>
  <div class="flex flex-col min-h-screen bg-blurple-500">
    <ContentGrid as="header" class="w-full pt-xl pb-l">
      <IconsLogoJuridika aria-hidden="true" class="fill-white h-full" />
    </ContentGrid>
    <main class="flex-1">
      <slot />
    </main>
    <Footer class="dark-mode" />
  </div>
</template>
